import { Link } from '@remix-run/react'

import { createEditEventPath } from '../../../features/editEvent/routes'
import { EventDetailsFragment } from '../../../graphql/fragments/eventDetails.fragment.gen'

interface EditButtonProps {
  event: EventDetailsFragment
  style: Style
}

export const EditEventButton: React.FC<EditButtonProps> = ({
  event,
  style,
}) => {
  return (
    <Link css={style} to={createEditEventPath(event.slug)} tabIndex={0}>
      <i className="fa fa-pen" />
      Edit
    </Link>
  )
}
