import React, { useState } from 'react'
import { DataProxy } from '@apollo/client/core'
import eventDetailsFragment from '../../graphql/fragments/eventDetails.fragment'
import { useCurrentUser } from '../../graphql/hooks'
import getSavedEventsQuery from '../../pages/SavedEvents/getSavedEvents.query'
import SignupPrompt from '../global/SignupPrompt'
import * as styles from './SaveEventButton.styles'
import Button from '../Button'
import { EventDetailsFragment } from '../../graphql/fragments/eventDetails.fragment.gen'
import {
  GetSavedEventsQuery,
  GetSavedEventsQueryVariables,
} from '../../pages/SavedEvents/getSavedEvents.query.gen'
import {
  useSaveEventMutation,
  useUnsaveEventMutation,
  SaveEventMutationVariables,
} from './saveEvent.mut.gen'

function useSaveEvent(event?: EventDetailsFragment) {
  const { user } = useCurrentUser()
  const [saveEvent] = useSaveEventMutation()
  const [unsaveEvent] = useUnsaveEventMutation()

  const [showSignupPrompt, setShowSignupPrompt] = useState(false)

  const onSave = () => {
    if (!user || !event) {
      setShowSignupPrompt(true)
      return
    }
    const { isSaved, id } = event

    const eventFragmentCacheId = `Event:${id}`

    const updateFragmentOptions: DataProxy.UpdateFragmentOptions<
      EventDetailsFragment,
      SaveEventMutationVariables
    > = {
      id: eventFragmentCacheId,
      fragment: eventDetailsFragment,
      fragmentName: 'EventDetails',
      optimistic: true,
    }

    const updateQueryOptions: DataProxy.UpdateQueryOptions<
      GetSavedEventsQuery,
      GetSavedEventsQueryVariables
    > = {
      query: getSavedEventsQuery,
      optimistic: true,
      variables: {
        pagination: {
          first: 7,
        },
      },
    }

    if (isSaved) {
      unsaveEvent({
        variables: { id },
        update(cache) {
          cache.updateFragment<EventDetailsFragment>(
            updateFragmentOptions,
            (fragmentData) => {
              if (!fragmentData) {
                return null
              }

              return { ...fragmentData, isSaved: false }
            },
          )

          cache.updateQuery(updateQueryOptions, (savedEventsData) => {
            if (!savedEventsData) {
              return null
            }

            return {
              getSavedEvents: {
                ...savedEventsData.getSavedEvents,
                edges: savedEventsData.getSavedEvents.edges.filter(
                  (edge) => edge.node.id !== id,
                ),
              },
            }
          })
        },
      })
    } else {
      saveEvent({
        variables: { id },
        update(cache) {
          cache.updateFragment<EventDetailsFragment>(
            updateFragmentOptions,
            (fragmentData) => {
              if (!fragmentData) {
                return null
              }

              return { ...fragmentData, isSaved: true }
            },
          )

          cache.updateQuery(updateQueryOptions, (savedEventsData) => {
            if (!savedEventsData) {
              return null
            }

            const event = cache.readFragment<EventDetailsFragment>({
              id: eventFragmentCacheId,
              fragment: eventDetailsFragment,
              fragmentName: updateFragmentOptions.fragmentName,
            })

            const isAlreadySaved = savedEventsData.getSavedEvents.edges.some(
              (edge) => edge.node.id === id,
            )

            if (!event || isAlreadySaved) {
              return null
            }

            return {
              getSavedEvents: {
                ...savedEventsData.getSavedEvents,
                edges: [
                  ...savedEventsData.getSavedEvents.edges,
                  {
                    node: event,
                    cursor: id,
                  },
                ],
              },
            }
          })
        },
      })
    }
  }

  const buttonText = event?.isSaved ? 'Saved' : 'Save'

  return {
    onSave,
    buttonText,
    showSignupPrompt,
  }
}

export const SaveEventButtonForCardFooter: React.FC<EventDetailsFragment> = (
  event,
) => {
  const { onSave, showSignupPrompt, buttonText } = useSaveEvent(event)
  const { isSaved } = event

  return (
    <>
      <button
        css={[styles.saveButtonForFooter, styles.savedColor(isSaved)]}
        onClick={onSave}
      >
        <i css={styles.icon} className="fas fa-heart" />
        {buttonText}
      </button>
      {showSignupPrompt && <SignupPrompt />}
    </>
  )
}

export const SaveEventButtonForDetailPage: React.FC<EventDetailsFragment> = (
  event,
) => {
  const { onSave, buttonText } = useSaveEvent(event)
  const { isSaved } = event

  return (
    <Button fullWidth onClick={onSave}>
      <i className="fas fa-heart" css={styles.savedColor(isSaved)} />
      {buttonText}
    </Button>
  )
}
