import { EventDetailsFragment } from '../../graphql/fragments/eventDetails.fragment.gen'
import { useCurrentUser } from '../../graphql/hooks'
import { Profile, Event } from '../../graphql/types.gen'
import { useRootLoader } from '../../root'
import { isUserEventParticipant } from '../../utilities/isUserEventParticipant'

export function useEventTicketsUrl(
  event: EventDetailsFragment | null | undefined,
) {
  let { user } = useCurrentUser()
  let { config } = useRootLoader()

  if (!event) {
    return ''
  }

  let isParticipant = isUserEventParticipant(user, event)

  let attribution = isParticipant ? user!.selectedProfile!.slug : null

  return createEventTicketsPath(event.slug, attribution, config.clientUrl)
}

function createEventTicketsPath(
  slug: Event['slug'],
  attribution: Profile['slug'] | null | undefined,
  clientUrl: string,
) {
  const url = new URL(`${clientUrl}/event/${slug}/tickets`)

  if (attribution) {
    url.searchParams.set('attribution', attribution)
  }

  return url.toString()
}
