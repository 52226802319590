const style: Style = ({ size, breakpoint }) => ({
  display: 'flex',
  position: 'fixed',
  bottom: 0,
  zIndex: 500,

  [breakpoint.medium]: {
    right: 0,
    marginRight: size.M,
    marginBottom: size.L,
  },
})

export default style
