import { Link } from '@remix-run/react'

import { createEventSummaryPath } from '../../../features/tickets/ticketRoutes'
import { primary } from '../../Button/Button.styles'
import { EventDetailsFragment } from '../../../graphql/fragments/eventDetails.fragment.gen'

interface SummaryButtonProps {
  event: EventDetailsFragment
  style?: Style
}

export const EventSummaryButton: React.FC<SummaryButtonProps> = ({
  event,
  style = primary({ fullWidth: true }),
}) => {
  return (
    <Link css={style} to={createEventSummaryPath(event.slug)} tabIndex={0}>
      <i className="fas fa-chart-pie" />
      Summary
    </Link>
  )
}
