import { Link } from '@remix-run/react'
import { css } from '@emotion/react'
import { mTheme } from '../../style/themes'
import { AcceptedIndicator } from './AcceptedIndicator'
import { EventDetailsFragment } from '../../graphql/fragments/eventDetails.fragment.gen'

export function getPromoterLink(promoter: EventDetailsFragment['promoter']) {
  if (!promoter) {
    return ''
  }

  return `/promoters/${promoter?.slug}`
}

const styles: Styles = {
  container: css({
    display: 'flex',
    alignItems: 'center',
    gap: mTheme.size.XS,
    paddingTop: mTheme.size.XXS,
    paddingBottom: mTheme.size.XXS,
    width: '100%',
    color: mTheme.fontColor.plain.tertiary,
    fontSize: mTheme.fontSize.S,
    ':visited': {
      color: mTheme.fontColor.plain.tertiary,
    },
    ':hover': {
      color: mTheme.fontColor.link.hover,
    },
  }),
  text: css({
    padding: mTheme.size.XS,
  }),
  icon: css({
    marginRight: mTheme.size.XXS,
  }),
}

type Props = {
  event: EventDetailsFragment
  userIsParticipant?: boolean
  icon?: boolean
}

export function PromoterLink({ event, userIsParticipant, icon }: Props) {
  const { promoter } = event

  if (!promoter) {
    return null
  }

  return (
    <Link to={getPromoterLink(promoter)} css={styles.container}>
      <span>
        {icon && <i css={styles.icon} className="fas fa-bullhorn" />} Presented
        by <span>{promoter.name}</span>
      </span>{' '}
      {userIsParticipant && (
        <AcceptedIndicator accepted={event.promoterAccepted} />
      )}
    </Link>
  )
}
