import { css } from '@emotion/react'
import { mTheme } from '../../style/themes'

const { size, fontColor } = mTheme
export const saveButtonForFooter: Style = css({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'baseline',
  paddingTop: size.S,
  paddingBottom: size.S,
  cursor: 'pointer',
  textTransform: 'uppercase',
  fontSize: size.S,
  letterSpacing: '1px',

  ':hover': {
    '&>span, i, &': {
      color: fontColor.plain.primary,
    },
  },
  ':visited': {
    color: fontColor.plain.secondary,
  },
})

export const savedColor =
  (isSaved: boolean | null | undefined): Style =>
  ({ fontColor, color }) => ({
    color: isSaved ? color.primary : fontColor.plain.secondary,
  })

export const icon: Style = css({
  margin: 0,
  padding: 0,
  marginRight: '0.3em',
  lineHeight: 1,
})
