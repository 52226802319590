import gql from 'graphql-tag'
import eventDetailsFragment from '../../graphql/fragments/eventDetails.fragment'

export default gql`
  query GetSavedEvents(
    # Query names that use pagination need to be added to the createCache function, otherwise they won't load properly
    # https://linear.app/yupty/issue/YUP-208/promoter-event-feed-flickers-when-loading-more
    $pagination: ForwardPagination!
  ) {
    getSavedEvents(pagination: $pagination) {
      edges {
        node {
          ...EventDetails
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  ${eventDetailsFragment}
`
