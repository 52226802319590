import { Link } from '@remix-run/react'

import { createScanTicketsPath } from '../../../features/tickets/ticketRoutes'
import { EventDetailsFragment } from '../../../graphql/fragments/eventDetails.fragment.gen'

interface ScanTicketsButtonButtonProps {
  event: EventDetailsFragment
  style: Style
}

export const ScanTicketsButton: React.FC<ScanTicketsButtonButtonProps> = ({
  event,
  style,
}) => {
  return (
    <Link css={style} to={createScanTicketsPath(event.slug)} tabIndex={0}>
      <i className="fa fa-camera" />
      Scan tickets
    </Link>
  )
}
