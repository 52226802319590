import { css } from '@emotion/react'
import { mTheme } from '../../style/themes'

type Props = {
  accepted?: boolean | null
}

const style: Style = css({
  opacity: 0.6,
  color: mTheme.fontColor.plain.tertiary,
  fontSize: mTheme.fontSize.S,
  position: 'relative',
  top: -1,
})

export function AcceptedIndicator({ accepted }: Props) {
  if (!accepted) {
    return null
  }

  return <i css={style} className="fas fa-check" />
}
