import { EventDetailsFragment } from '../../graphql/fragments/eventDetails.fragment.gen'

export function getEventTicket(
  event: EventDetailsFragment,
): EventDetailsFragment['products'][0] | null {
  if (event.products.length === 0) {
    return null
  }

  return event.products[0]
}
