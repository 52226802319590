import { useDeleteEventMutation } from '../../../graphql/mutations/event.mut.gen'
import { EventDetailsFragment } from '../../../graphql/fragments/eventDetails.fragment.gen'

type DeleteEventButtonProps = {
  event: EventDetailsFragment
  onClick?: () => void
  style: Style
}

export default function DeleteEventButton({
  event,
  onClick,
  style,
}: DeleteEventButtonProps) {
  const [deleteEvent] = useDeleteEventMutation()

  return (
    <button
      css={style}
      onClick={() => {
        if (
          confirm(
            'Are you sure you want to delete this event? This cannot be undone.',
          )
        ) {
          deleteEvent({ variables: { id: event.id } })
          onClick?.()
        }
      }}
      tabIndex={0}
      role="button"
      data-testid="delete-event-button"
    >
      <i className="fa fa-trash" />
      Delete
    </button>
  )
}
