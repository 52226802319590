import gql from 'graphql-tag'
import { promoterDetailsFragment } from './promoterDetails.fragment'

export default gql`
  fragment EventDetails on Event {
    id
    published
    slug
    name
    description
    picture
    startTime
    products {
      id
      name
      price {
        amount
      }
      quantity
    }
    isSaved

    venue {
      id
      name
      slug
      picture
      ageRestriction
      city {
        id
        name
      }
    }
    venueAccepted

    genres {
      id
      name
    }

    artists {
      id
      name
      slug
      picture
      genres {
        id
        name
      }
    }
    artistIdsAccepted

    promoter {
      ...PromoterDetails
    }
    promoterAccepted
    allParticipantsAccepted
  }

  ${promoterDetailsFragment}
`
