import { EventDetailsFragment } from '../graphql/fragments/eventDetails.fragment.gen'
import { CurrentUserQuery } from '../graphql/queries/user/currentUser.query.gen'

export const isUserEventParticipant = (
  user: CurrentUserQuery['currentUser'],
  event: EventDetailsFragment,
): boolean => {
  if (!user) {
    return false
  }

  const { selectedProfile } = user

  if (!selectedProfile) {
    return false
  }

  if (selectedProfile.__typename === 'Artist') {
    const artistIndex = event.artists.findIndex(
      (artist) => artist.id === selectedProfile.id,
    )

    return artistIndex !== -1
  }

  if (selectedProfile.__typename === 'Venue') {
    return selectedProfile.id === event.venue?.id
  }

  if (selectedProfile.__typename === 'Promoter' && event.promoter) {
    return selectedProfile.id === event.promoter.id
  }

  return false
}
