/* eslint-disable */
import * as Types from '../../graphql/types.gen'

import { gql } from '@apollo/client/index.js'
import * as Apollo from '@apollo/client/index.js'
const defaultOptions = {} as const
export type SaveEventMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type SaveEventMutation = {
  __typename?: 'Mutation'
  saveEvent?: { __typename?: 'Event'; id: string; slug: string } | null
}

export type UnsaveEventMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type UnsaveEventMutation = {
  __typename?: 'Mutation'
  unsaveEvent?: { __typename?: 'Event'; id: string; slug: string } | null
}

export const SaveEventDocument = gql`
  mutation saveEvent($id: ID!) {
    saveEvent(id: $id) {
      id
      slug
    }
  }
`
export type SaveEventMutationFn = Apollo.MutationFunction<
  SaveEventMutation,
  SaveEventMutationVariables
>

/**
 * __useSaveEventMutation__
 *
 * To run a mutation, you first call `useSaveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEventMutation, { data, loading, error }] = useSaveEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSaveEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveEventMutation,
    SaveEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveEventMutation, SaveEventMutationVariables>(
    SaveEventDocument,
    options,
  )
}
export type SaveEventMutationHookResult = ReturnType<
  typeof useSaveEventMutation
>
export type SaveEventMutationResult = Apollo.MutationResult<SaveEventMutation>
export type SaveEventMutationOptions = Apollo.BaseMutationOptions<
  SaveEventMutation,
  SaveEventMutationVariables
>
export const UnsaveEventDocument = gql`
  mutation unsaveEvent($id: ID!) {
    unsaveEvent(id: $id) {
      id
      slug
    }
  }
`
export type UnsaveEventMutationFn = Apollo.MutationFunction<
  UnsaveEventMutation,
  UnsaveEventMutationVariables
>

/**
 * __useUnsaveEventMutation__
 *
 * To run a mutation, you first call `useUnsaveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsaveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsaveEventMutation, { data, loading, error }] = useUnsaveEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnsaveEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsaveEventMutation,
    UnsaveEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnsaveEventMutation, UnsaveEventMutationVariables>(
    UnsaveEventDocument,
    options,
  )
}
export type UnsaveEventMutationHookResult = ReturnType<
  typeof useUnsaveEventMutation
>
export type UnsaveEventMutationResult =
  Apollo.MutationResult<UnsaveEventMutation>
export type UnsaveEventMutationOptions = Apollo.BaseMutationOptions<
  UnsaveEventMutation,
  UnsaveEventMutationVariables
>
