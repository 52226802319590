import { css } from '@emotion/react'
import { mTheme } from '../../style/themes'

let { fontSize, fontColor, size } = mTheme

export const linkContainer: Style = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textTransform: 'uppercase',
  margin: 0,
  fontFamily: 'sans-serif',
})

export const detailsText = css({
  color: fontColor.plain.tertiary,
  opacity: 0.5,
  fontSize: fontSize.XS,
  marginTop: size.XXS,
  width: '100%',
  textAlign: 'justify',
  // letterSpacing: 1.2,
})

export const weekday: Style = ({ fontColor }) => ({
  display: 'flex',
  color: fontColor.calendarWeekday,
  fontSize: '1.2rem',
  lineHeight: '1.2rem',
  fontWeight: 'bold',
  justifyContent: 'space-between',
  textAlign: 'center',
  width: '100%',
})

export const spacedLetter: Style = css({
  textAlign: 'center',
})

export const day: Style = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  textTransform: 'uppercase',
  color: fontColor.plain.primary,
  fontSize: fontSize.XXL,
  margin: 0,
  padding: 0,
  lineHeight: fontSize.XXL,
  fontWeight: 'bold',
})

export const time: Style = css({
  textAlign: 'center',
  color: fontColor.plain.secondary,
  fontSize: fontSize.M,
  lineHeight: fontSize.L,
  fontWeight: 'bold',
})

export const month: Style = css({
  display: 'flex',
  fontSize: '1.2rem',
  lineHeight: '1.2rem',
  justifyContent: 'space-between',
  textAlign: 'center',
  color: fontColor.plain.primary,
  width: '100%',
})
