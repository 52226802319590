import { Link } from '@remix-run/react'
import { EventDetailsFragment } from '../../../graphql/fragments/eventDetails.fragment.gen'

interface ReportButtonProps {
  event: EventDetailsFragment
  style: Style
}

export const ReportEventButton: React.FC<ReportButtonProps> = (props) => {
  return (
    <Link css={props.style} to="/contact" tabIndex={0}>
      <i className="fa fa-bug" />
      Report
    </Link>
  )
}
