import { Link } from '@remix-run/react'
import {
  weekdayFormat,
  dayFormat,
  monthFormat,
} from '../../utilities/dateTimeUtils'

import * as styles from './EventDate.styles'

interface EventDateProps {
  date: Date
  eventSlug?: string | null
}

export const EventDate: React.FC<EventDateProps> = ({ eventSlug, date }) => {
  let dateComponent = (
    <>
      <span css={styles.weekday}>
        {
          Array.prototype.map.call(weekdayFormat.format(date), (letter) => (
            <span css={styles.spacedLetter} key={letter}>
              {letter}
            </span>
          )) as React.ReactNode
        }
      </span>
      <span css={styles.day}>{dayFormat.format(date)}</span>
      <span css={styles.month}>
        {
          Array.prototype.map.call(monthFormat.format(date), (letter) => (
            <span css={styles.spacedLetter} key={letter}>
              {letter}
            </span>
          )) as React.ReactNode
        }
      </span>
      {/* {includeTime && <span css={styles.time}>{date.format('HH:mm')}</span>} */}
    </>
  )

  if (eventSlug) {
    return (
      <Link css={styles.linkContainer} to={`/event/${eventSlug}`}>
        {dateComponent}
        <span css={styles.detailsText}>Details</span>
      </Link>
    )
  }

  return <div css={styles.linkContainer}>{dateComponent}</div>
}

export default EventDate
